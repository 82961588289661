import AdminRoutes from "../components/pagesComponents/adminRoutes/AdminRoutes";

const AdminPage = () => {
    return ( 
        <div >
            {/* style={{ width: "100%", maxWidth: "80rem", margin: "7rem auto" }} */}
            <AdminRoutes />
        </div>
     );
}
 
export default AdminPage;