import OrdersHistory from "../components/pagesComponents/orders/Orders";

const OrdersPage = () => {
    
    return ( 
        <div >
            {/* style={{ width: "100%", maxWidth: "50rem", margin: "10rem auto" }} */}
            <OrdersHistory />
        </div>
     );
}
 
export default OrdersPage;