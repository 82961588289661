import AuthForm from "../components/pagesComponents/auth/AuthForm";

const RegisterPage = () => {
    return ( 
        <div >
            {/* style={{width: "100%", maxWidth: "60rem", margin: "10rem auto"}} */}
            {/* <h2>RegisterPage</h2> */}
            <AuthForm />
        </div>
     );
}
 
export default RegisterPage;