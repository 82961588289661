import ContactForm from "../components/pagesComponents/contactUs/ContactForm";

const ContactPage = () => {
    return ( 
        <div >
            {/* style={{ width: "100%", maxWidth: "60rem", margin: "10rem auto" }} */}
            <h2 style={{textAlign:"center"}}>ContactPage</h2>
            <ContactForm />
        </div>
     );
}
 
export default ContactPage;