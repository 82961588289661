import Home from "../components/pagesComponents/home/Home";


const HomePage = () => {
    return ( 
        <div >
            {/* style={{width: "100%", maxWidth: "60rem", margin: "10rem auto"}} id="productsSlider" */}
            <h2  style={{textAlign:"center"}}>HomePage</h2>
            <Home />
        </div>
     );
}
 
export default HomePage;