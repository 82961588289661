import Checkout from "../components/pagesComponents/checkout/Checkout";

const CheckoutPage = () => {
    return ( 
        <div >
            {/* style={{ width: "100%", maxWidth: "60rem", margin: "10rem auto" }} */}
            {/* <h2>CheckoutPage Baby!!!</h2> */}
            <Checkout />
        </div>
     );
}
 
export default CheckoutPage;