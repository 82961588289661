import Profile from "../components/pagesComponents/userProfile/Profile";

const ProfilePage = () => {

  return (
    <div>
      <Profile />
    </div>
  );
};

export default ProfilePage;
