 import Slider from "../../slider/Slider";


const Home = () => {
    return ( 
        <div>
           <Slider /> 
        </div>
     );
}
 
export default Home;