import ShopNow from "../components/pagesComponents/shopNow/ShopNow";

const ShopNowPage = () => {
    return ( 
        <div >
            <h1>Shop Now Page</h1>
            <ShopNow />
        </div>
     );
}
 
export default ShopNowPage;