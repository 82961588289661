import Cart from "../components/pagesComponents/cart/Cart";

const CartPage = () => {
    
    return ( 
        <div >
            <h2 style={{textAlign:"center"}}>Cart Page</h2>
            <Cart />
        </div>
     );
}
 
export default CartPage;